export const AddStudentModeType = {
  MANUAL: 'manual',
  CLASS_CODE: 'classCode',
} as const;

export type AddStudentModeType =
  (typeof AddStudentModeType)[keyof typeof AddStudentModeType];

export const IdpType = {
  NATIVE: 'native',
  CLEVER: 'clever',
  CLEVER_M: 'clever-m',
  CLASSLINK: 'classlink',
  GOOGLE: 'google',
  UNKNOWN: 'unknown',
} as const;

export type IdpType = (typeof IdpType)[keyof typeof IdpType];

export const UserType = {
  TEACHER: 'Teacher',
  STUDENT: 'Student',
  PARENT: 'Parent',
  SCHOOL_ADMIN: 'SchoolAdmin',
  DISTRICT_ADMIN: 'DistrictAdmin',
  UNKNOWN: 'Unknown',
} as const;

export type UserType = (typeof UserType)[keyof typeof UserType];

export type ParentProxyType = {
  email: string;
  firstName: string;
  lastName?: string;
  middleName?: string;
  pid: string;
  invitationStatus: {
    acceptedDate?: number;
    declinedDate?: number;
    lastSendDate?: number;
    openedDate?: number;
    optOutDate?: number;
    status: string;
  };
  consentStatus: {
    declinedDate?: number;
    acceptedDate?: number;
    status: string;
  };
};

export type StudentProxyType = {
  firstName: string;
  lastName?: string;
  middleName?: string;
  sid: string;
  userId: string;
  username: string;
  listOfParentProxies: Array<ParentProxyType>;
  login?: string;
  password?: string;
};

export interface ClassroomType {
  addStudentMode?: AddStudentModeType;
  className: string;
  classroomId: string;
  email: string;
  grade: number;
  language: string;
  readingLevel: number;
  readAloudLevelScheme?: string;
  listOfStudentProxies: StudentProxyType[];
  completionStatus: Record<
    string,
    Record<string, Record<string, string>> | string[][][]
  >;
  isArchived?: boolean;
  createdAt: number;
  modifiedAt: number;
  peekavilleUnitLocks?: Record<string, boolean>;
  teachersUserId: string;
  externalClassroomId?: string;
}

export interface ExternalClassroomType extends ClassroomType {
  externalClassroomId: string;
}

export type MyPeekavilleUnitLocksObjectType = {
  // myPeekaville.com project has this unit name without the hyphen
  'Self-Regulation'?: boolean;
  'Self Regulation'?: boolean;
  Respect: boolean;
  Kindness: boolean;
  Perseverance: boolean;
  Teamwork: boolean;
  Empathy: boolean;
  Honesty: boolean;
  Optimism: boolean;
  Courage: boolean;
};

export interface UserProfileType {
  salutation?: string;
  role?: string;
  schoolZip?: string;
  schoolName?: string;
  schoolCity?: string;
  schoolState?: string;
  classroomId: string;
  cognitoUserId: string;
  country: string;
  createMethod: string;
  createdAt: number;
  defaultStudentPassword: string;
  district?: string;
  schoolId?: string;
  seller?: string;
  email: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  flags: {
    isShowAutoPlay?: boolean;
    isShowConnectParentsHint?: boolean;
    isShowControl?: boolean;
    isShowLibraryBanner?: boolean;
    isSkipAutoSync?: boolean;
    isDoneClassroomTrial?: boolean;
    isLicenseDoneTrial?: boolean;
  };
  timestamps: {
    lastArchiveClassroomPrompt?: number;
  };
  grade: number;
  keyRing: string[];
  lastAccessTime?: number;
  lastModifiedAt?: number;
  modifiedAt?: number;
  licenseExpires: number;
  licenseLevel: string;
  messageMark?: number;
  optIn: boolean;
  password: string;
  userId: string;
  type: UserType;
  cleverAccountUserId?: string;
  cleverAccessToken?: {
    token: string;
    exp: number;
  };
  googleAccountUserId?: string;
  sessionExpired?: boolean;
  stripeCustomer?: {
    last4: string;
    exp_month: number;
    exp_year: number;
    brand: string;
  };
  providerName?: string;
  [k: string]: any;
}

export type StudentDBObjectType = {
  classroomId: string;
  createMethod?: string;
  district?: string;
  email?: string;
  firstName: string;
  grade: number;
  middleName?: string;
  readingLevel: number;
  password: string;
  lastName?: string;
  licenseLevel?: string;
  licenseExpires?: number;
  login?: string;
  teachersUserId: string;
  schoolId?: string;
};

export interface GetCleverTokenResponse {
  access_token: string;
  token_type: string;
}

export type CleverLink = {
  rel: string;
  uri: string;
};

export type CleverId = {
  type: string;
  data: {
    id: string;
    district: string;
    type: string;
  };
  links: Array<CleverLink>;
};

export type CleverProfile = {
  data: {
    district: string;
    email: string;
    name: {
      first: string;
      last: string;
      middle: string;
    };
    schools: Array<string>;
    id: string;
  };
  links: Array<CleverLink>;
};

// derived from API testing
export interface CleverApiSectionResult {
  data: CleverApiSection[];
}

export interface CleverApiSection {
  data: CleverSectionData;
  uri: string;
}

export interface CleverSectionData {
  created: string;
  district: string;
  grade: string;
  last_modified: string;
  name: string;
  period: string;
  school: string;
  section_number: '';
  sis_id: string;
  students: string[];
  subject: string;
  teacher: string;
  teachers: string[];
  id: string;
}

export interface CleverApiStudentResult {
  data: CleverApiStudent[];
}

export interface CleverApiStudent {
  data: CleverStudentData;
  uri: string;
}

export interface CleverStudentData {
  created: string;
  district: string;
  email: string;
  last_modified: string;
  name: {
    first: string;
    last: string;
    middle: string;
  };
  roles: {
    student: {
      credentials: {
        district_username: string;
      };
      dob: string;
      enrollments: string[];
      gender: string;
      grade: string;
      graduation_year: string;
      hispanic_ethnicity: string;
      location: {
        address: string;
        city: string;
        lat: string;
        lon: string;
        state: string;
        zip: string;
      };
      race: string;
      school: string;
      schools: string[];
      sis_id: string;
      state_id: string;
      student_number: string;
    };
  };
  id: string;
}

export type StudentId = string;
export type SectionId = string;
export type ProviderManagedUserId = string;
export type ProviderManagedAccessToken = string;

export interface NormalizedExternalStudent {
  profile: {
    id: StudentId;
    name: {
      givenName: string;
      familyName?: string;
      middleName?: string;
    };
    emailAddress?: string;
    grade: string;
  };
}

export interface ExternalSection {
  id: SectionId;
  name: string;
  section?: string;
  grade?: string;
  modifiedAt?: number;
  students: StudentId[] | NormalizedExternalStudent[];
}

export interface NormalizedExternalSection extends ExternalSection {
  students: NormalizedExternalStudent[];
}

export interface UserPropertyAccessor {
  getApiUserId: () => string | undefined;
  getApiAccessToken: () => string | undefined;
  isUserSignedIn: () => boolean;
}

export type CognitoCredentialsFromClever = {
  username: string;
  password: string;
};

export type CheckButtonEvent = {
  target: {
    checked: boolean;
  };
};

export type PageTracker = {
  previousPage?: string;
  timeCount?: number;
  timeEntered?: number;
  timeSpentOnPreviousPage?: number;
  currentPage?: string;
  refreshCount?: number;
};

export interface MergeOrSyncClassroomRequest {
  operation: 'merge' | 'sync';
}

export interface SyncClassroomRequest extends MergeOrSyncClassroomRequest {
  operation: 'sync';
  idP: IdpType;
  defaultStudentPassword: string;
  rawClassroomData: NormalizedExternalSection;
  teachersUserId: string;
}

export interface MergeClassroomRequest extends MergeOrSyncClassroomRequest {
  operation: 'merge';
  mergeIntoClassroomId: string;
}

export type SchoolModel = {
  id: string;
  name: string;
  district: string;
  address: string;
  city: string;
  state: string;
  combineIds?: string[];
  createdAt?: number;
  modifiedAt?: number;
  pmDistrict?: string;
  pmSchool?: string;
  zip?: string;
  country?: string;
  isArchived?: boolean;
};

export interface SignUpData {
  name?: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  optin: boolean;
  toupp: boolean;
  redeemableCode?: string;
  curriculum: string;
}

export interface FetchConfiguration {
  method: string;
  headers: Record<string, string>;
  body?: string;
}

export interface SellerNames {
  [id: string]: {
    name: string;
  };
}

export interface DistrictNames {
  [id: string]: {
    city: string;
    name: string;
    state: string;
    zip: string;
  };
}

export interface JobError extends Error {
  time: number;
  requestId: string;
  relatedUid: string;
}

export interface ClassLinkApiSection {
  sourcedId: string;
  status: string;
  dateLastModified: string;
  title: string;
  classCode: string;
  classType: string;
  location: string;
  course: {
    href: string;
    sourcedId: string;
    type: 'course';
  };
  school: {
    href: string;
    sourcedId: string;
    type: 'org';
  };
}

export interface ClassLinkApiSectionResult {
  classes: ClassLinkApiSection[];
}

export interface ClassLinkApiStudent {
  sourcedId: string;
  status: string;
  dateLastModified: string;
  username: string;
  userIds: string[];
  enabledUser: 'true' | 'false';
  givenName: string;
  familyName: string;
  middleName: string;
  role: 'student';
  identifier: string;
  email: string;
  sms: string;
  phone: string;
  agents: string[];
  orgs: Array<{
    href: string;
    sourcedId: string;
    type: 'org';
  }>;
  grades: string[];
  password: string;
}

export type ClassLinkApiStudentWithSection = ClassLinkApiStudent & {
  sectionSourcedId: string;
};

export interface ClassLinkApiStudentResult {
  users: ClassLinkApiStudent[];
}

export interface ElementaryUnit {
  key: 'unit';
  value: {
    lastVersion: number;
    name: string;
    version: string;
    lessonPlan: {
      about: { text: string };
      activities: { activities: number; label: string; title: string }[][];
      availableLanguages: string[];
      availableGrades: string[];
      epilogue: { label: string; text: string }[];
      files: { actualName: string; displayName: string }[];
      grade: string;
      headerImage: string;
      label: string;
      learningOutcomes: string[];
      origin: string;
      prologue: { label: string; text: string }[];
      seoKeywords: string[] | [];
      seoTitle: string;
      story: string;
      subUnits: {
        contentType: string;
        label: string;
        lessons: {
          duration: string;
          activityType: string;
          contentType: string;
          description: { text: string };
          label: string;
          learningOutcomes: string[];
          orContentAccessKeys?: string[];
          orContentAccessKey?: string;
          andContentAccessKey?: string;
          andContentAccessKeys?: string[];
          title: string;
          prologue: { label: string; text: string }[];
          activities: {
            activityInfo?: { text: string };
            additionalStandards?: string[];
            content: { text: string };
            classUpdate?: { text: string };
            contentLabel: string;
            contentType: string;
            coreStandards: string[];
            description: { text: string };
            duration: string;
            files: { actualName: string; displayName: string }[];
            label: string;
            learningOutcomes: string[] | [];
            prologue: { label: string; text: string }[];
            slideShowId: string;
            teacherTip: string;
            title: string;
            videoUrl?: string;
            thumbnailUrl?: string;
            videoTitle?: string;
          }[];
        }[];
        overview: { text: string };
        teacherTip: string;
        title: string;
        url: string;
      }[];
      title: string;
    };
  };
}

export interface MessageType {
  date: number;
  id: string;
  subject: string;
  version: string;
  authorRole: string;
  verb: string;
  studentsName: string;
  targetRole: string;
  target: string;
  noun: string;
  author: string;
  type: string;
}

export interface JournalEntry {
  firstName?: string;
  lastName?: string;
  classroomId?: string;
  type: 'feelings-checkin' | 'struggle' | 'comprehension';
}

export interface FeelingsJournalEntry extends JournalEntry {
  feeling: string;
  type: 'feelings-checkin';
  journalEntry?: string;
  isTeacherRequested?: boolean;
}

export interface StruggleJounralEntryData {
  dataVersion: number;
  gameId: string;
  levelPlayed: number;
  rightNum: number;
  wrongNum: number;
}

export type StruggleJournalEntry = JournalEntry &
  StruggleJounralEntryData & {
    type: 'struggle';
  };

export interface ComprehensionJournalEntryData {
  book: number;
  level: number;
  time: number;
  numRight: number;
  numWrong: number;
  individualQuestionsData: Array<{
    // choice play made
    choice: number;
    // ID of the question
    id: string;
    // whether player got the answer right or wrong
    rightOrWrong: 'right' | 'wrong';
  }>;
}

export type ComprehensionJournalEntry = JournalEntry &
  ComprehensionJournalEntryData & {
    type: 'comprehension';
  };

export interface GameStateData {
  [k: string]: unknown;
  unitLockDictionary: UnitLockDictionary;
}

export interface RetrieveGameStateResult {
  userId: string;
  firstName: string;
  lastName: string;
  licenseLevel: string;
  licenseExpires: number;
  readingLevel: number;
  seller?: string;
  account?: string;
  classroomId?: string;
  schoolId?: string;
  district?: string;
  userType: UserType;
  gameState: GameStateData;
}

export interface UnitLockDictionary {
  'Self Regulation': boolean;
  Respect: boolean;
  Gratitude: boolean;
  Kindness: boolean;
  Teamwork: boolean;
  Empathy: boolean;
  Honesty: boolean;
  Optimism: boolean;
  Courage: boolean;
  Perseverance: boolean;
}

export interface GetCountryOriginResult {
  country_code: string;
  country: string;
  region_code: string;
  region: string;
  sourceIp: string;
}
