import StandalonePageWrapper from './StandalonePageWrapper';
import { cognitoConfiguration } from './ApplicationState';

const mypeekavilleCommitHash = process.env.REACT_APP_MYPEEKAVILLE_COMMIT_HASH
  ? process.env.REACT_APP_MYPEEKAVILLE_COMMIT_HASH
  : 'N/A';

const mypeekavilleBranchName = process.env.REACT_APP_MYPEEKAVILLE_BRANCH_NAME
  ? process.env.REACT_APP_MYPEEKAVILLE_BRANCH_NAME
  : 'N/A';

const newPeekavilleCommitHash = process.env.REACT_APP_NEWPEEKAVILLE_COMMIT_HASH
  ? process.env.REACT_APP_NEWPEEKAVILLE_COMMIT_HASH
  : 'N/A';

const newPeekavilleBranchName = process.env.REACT_APP_NEWPEEKAVILLE_BRANCH_NAME
  ? process.env.REACT_APP_NEWPEEKAVILLE_BRANCH_NAME
  : 'N/A';

const microservicesCommitHash = process.env.REACT_APP_MICROSERVICES_COMMIT_HASH
  ? process.env.REACT_APP_MICROSERVICES_COMMIT_HASH
  : 'N/A';

const microservicesBranchName = process.env.REACT_APP_MICROSERVICES_BRANCH_NAME
  ? process.env.REACT_APP_MICROSERVICES_BRANCH_NAME
  : 'N/A';

const commonCommitHash = process.env.REACT_APP_COMMON_COMMIT_HASH
  ? process.env.REACT_APP_COMMON_COMMIT_HASH
  : 'N/A';

const commonBranchName = process.env.REACT_APP_COMMON_BRANCH_NAME
  ? process.env.REACT_APP_COMMON_BRANCH_NAME
  : 'N/A';

const buildDate = process.env.REACT_APP_BUILD_DATE
  ? process.env.REACT_APP_BUILD_DATE
  : 'N/A';

export const frontendVersionDescription = `[ ${mypeekavilleBranchName} ] ${mypeekavilleCommitHash}`;

const VersionInformation = (props) => (
  <StandalonePageWrapper>
    <div className='standaloneMessageWrapper'>
      <h1>Version Information</h1>
      <p>
        <strong>
          mypeekaville.com Branch <em>{mypeekavilleBranchName}</em> commit hash:{' '}
        </strong>
        {mypeekavilleCommitHash}
      </p>
      <p>
        <strong>
          NewPeekaville<em>{newPeekavilleBranchName}</em> commit hash:{' '}
        </strong>
        {newPeekavilleCommitHash}
      </p>
      <p>
        <strong>
          Microservices Branch <em>{microservicesBranchName}</em> commit hash:{' '}
        </strong>
        {microservicesCommitHash}
      </p>
      <p>
        <strong>
          Common Branch <em>{commonBranchName}</em> commit hash:{' '}
        </strong>
        {commonCommitHash}
      </p>
      <p>
        <strong>Build date: </strong>
        {buildDate}
      </p>
      {props.userProfile &&
        props.userProfile.keyRing.includes('KEY_MASTER_KEY') && (
          <div>
            <p>
              <strong>Cognito Configuration: </strong>
              <br />
              {`${JSON.stringify(cognitoConfiguration, null, 2)}`}
            </p>
          </div>
        )}
    </div>
  </StandalonePageWrapper>
);

export default VersionInformation;
