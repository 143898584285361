// @flow
import { Component } from 'react';
import Spinner from 'react-spinkit';
import styles from './SCSS/NewSignUpError.module.scss';
import utilityStyles from './SCSS/common/_utilityStyles.scss';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';

export class NewSignUpStatusUpdate extends Component {
  render() {
    return (
      <div className={styles.occupyWholePage}>
        <div className={styles.content}>
          <div className={styles.contentPicture}>
            <img alt='Progress status' src={this.props.pictureUrl} />
          </div>
          <div className={styles.contentText}>
            <div className={styles.contentTitle}>{this.props.contentTitle}</div>
            <div className='spacer-40'></div>
            <div className={styles.contentSubtitle}>
              {this.props.contentSubtitle}
              {this.props.isShowSpinner && <Spinner />}
            </div>
          </div>
        </div>
        {this.props.buttonText && (
          <div>
            <div className='spacer-40'></div>
            <div className={utilityStyles.absCentre}>
              <PrimaryButton onClick={this.props.onButtonClick}>
                {this.props.buttonText}
              </PrimaryButton>
            </div>
          </div>
        )}
        <div className='spacer-40'></div>
      </div>
    );
  }
}

export default NewSignUpStatusUpdate;
