import { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  openHostedUI,
  Provider,
  reactivateUserSession,
} from './UserManagementHelpers';
import Main from './Main';
import { play, playTryout, stop } from './GameState';
import { EntryStates } from './EntryState';
import OauthLogin from './OauthLogin';
import PreauthLogin from './OauthPreAuthorizedLogin';
import LoginDashboard from './LoginDashboard';
import GameContainer from './GameContainer';
import Authenticated from './Authenticated';
import GamePortal from './GamePortal';
import Logout from './Logout';
import ParentalConsent from './ParentalConsent';
import SignUp from './SignUp';
import ApplicationConsole from './ApplicationConsole';
import VersionInformation from './VersionInformation';
import ToolBar from './ToolBar';
import NonExistentAccountPage from './NonExistentAccountPage';
import DynamicErrorMessage from './DynamicErrorMessage';
import styles from './SCSS/App.module.scss';
import { deleteCookie, getCookie, noWhiteSpace } from './GlobalFunctions';
import Analytics from './Analytics';
import { InteractionEndTrigger } from './peekapak-types/AnalyticsTypes';
import { UserProfileType } from './peekapak-types/DataProtocolTypes';
import { LoginState } from './UserManagement';

type Props = {
  location: {
    pathname: string;
  };
  loginState: LoginState;
  entryState: string;
  userProfile: UserProfileType;
  onPlay: () => void;
  onPlayTryout: () => void;
  onStop: () => void;
};

type State = {
  isShowDynamicErrorMessage: boolean;
  errorMessage: string | ((arg0: {}) => void);
  visibleStart: number | undefined;
  hiddenStart: number | undefined;
};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowDynamicErrorMessage: false,
      errorMessage: '',
      visibleStart: Date.now(),
      hiddenStart: undefined,
    };
  }

  showDynamicErrorMessage = (message: string) => {
    this.setState({
      isShowDynamicErrorMessage: true,
      errorMessage: message,
    });
  };
  closeDynamicErrorMessage = () => {
    this.setState({
      isShowDynamicErrorMessage: false,
      errorMessage: '',
    });
  };

  async componentDidMount() {
    this.setupOnVisibilityChangeHandler();
    try {
      const reloginDetected = getCookie('peekapak.reloginIdp');
      if (reloginDetected) {
        deleteCookie('peekapak.reloginIdp');
        const idp = getCookie('peekapak.idP');
        openHostedUI(idp as Provider);
      } else {
        await reactivateUserSession();
      }
    } catch (error) {
      const message = processError(error);

      if (message === 'User does not have account') {
        this.props.history.replace('/noAccount'); // signout intentionally disabled because
        // signout from OAuth login will cause a redirect
        // to / causing whatever the user was doing to be
        // aborted
        // setTimeout( signOutUser, 15000 );
      } else {
        this.props.onShowErrorMessage(message);
      }
    }

    if (
      this.props.location.pathname.includes('game') &&
      this.props.entryState === EntryStates.tryoutMode
    ) {
      this.props.onPlayTryout();
    } else if (this.props.location.pathname.includes('game')) {
      this.props.onPlay();
    } else {
      this.props.onStop();
    }

    function processError(error) {
      if (error === `{'error':'invalid_grant'}`) {
        return 'There was a problem. Please try signing in again.';
      }

      const message = getUserFriendlyErrorMessage(error);

      if (!message) {
        // logger.logException( new Error( `Google login ${ that.props.location.pathname } error: ${ error }` ) );
        console.error(
          `Google/Clever login error = ${JSON.stringify(error, null, 2)}`,
        );
      }

      return message;
    }

    function getUserFriendlyErrorMessage(error) {
      if (!error.message) {
        return '';
      }

      const idP = getCookie('peekapak.idP');

      switch (error.message) {
        case 'User Pool account already exists':
          // logger.logException( new Error( `Google login ${ that.props.location.pathname } error: ${ error.message || error }` ) );
          return noWhiteSpace`A Peekapak account with this email \
            address already exists. Please use the email address and associated password \
            to login instead of the ${idP} Sign In feature. Please use your email \
            address and password to sign in`;

        case 'User does not have account':
        default:
          return error.message;
      }
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.location.pathname.includes('game') &&
      this.props.entryState === EntryStates.tryoutMode
    ) {
      this.props.onPlayTryout();
    } else if (this.props.location.pathname.includes('game')) {
      this.props.onPlay();
    } else {
      this.props.onStop();
    }
  }

  componentWillUnmount(): void {
    this.teardownOnVisibilityChangeHandler();
  }

  teardownOnVisibilityChangeHandler = () => {
    document.removeEventListener(
      'visibilitychange',
      this.handleOnVisibilityChange,
    );
  };

  setupOnVisibilityChangeHandler = () => {
    document.addEventListener(
      'visibilitychange',
      this.handleOnVisibilityChange,
    );
  };

  handleOnVisibilityChange = async (event: Event) => {
    event.preventDefault();
    // tracker.endInteractionOnLeavePage();
    // document.removeEventListener('visibilitychange', handleOnVisibilityChange);

    if (document.visibilityState === 'hidden') {
      const duration = getDuration(this.state.visibleStart!);
      if (this.props.userProfile) {
        await Analytics.interactionEnd(
          this.props.userProfile,
          duration,
          InteractionEndTrigger.UNLOAD,
        );
        console.debug(
          `%cHIDDEN visible duration = ${duration}, INTERACTION END`,
          `color: green; background-color: black`,
        );
      } else {
        console.debug(
          `%cHIDDEN visible duration = ${duration}`,
          `color: green; background-color: black`,
        );
      }

      this.setState({
        hiddenStart: Date.now(),
        visibleStart: undefined,
      });
    } else if (document.visibilityState === 'visible') {
      // tracker.startInteractionOnReturnToPage();

      const duration = getDuration(this.state.hiddenStart!);

      if (this.props.userProfile) {
        console.debug(
          `%cVISIBLE hidden duration = ${duration}, INTERACTION START`,
          `color: green; background-color: black`,
        );

        await Analytics.interactionStart(this.props.userProfile);
      } else {
        console.debug(
          `%cVISIBLE hidden duration = ${duration}`,
          `color: green; background-color: black`,
        );
      }

      this.setState({
        visibleStart: Date.now(),
        hiddenStart: undefined,
      });
    } else {
      throw new Error('Unknown visibility state');
    }
  };

  render() {
    return (
      <>
        {this.state.isShowDynamicErrorMessage ? (
          <DynamicErrorMessage
            isShow={this.state.isShowDynamicErrorMessage}
            message={this.state.errorMessage}
            onClose={this.closeDynamicErrorMessage}
            {...this.props}
          />
        ) : (
          <div className={styles['App-layout']}>
            <ToolBar className={styles['App-toolbar']} {...this.props} />
            <Main className={styles['App-main']}>
              <Switch>
                <Route exact path='/' render={() => <Redirect to='/login' />} />
                <Route path='/login' component={LoginDashboard} />
                <Route path='/teacherLogin' component={LoginDashboard} />
                <Authenticated path='/gamePortal' component={GamePortal} />
                <Authenticated
                  path='/game'
                  component={GameContainer}
                  {...this.props}
                />
                <Route path='/signUp/:classCode?' component={SignUp} />
                <Redirect from='/noParentalConsent' to='/login' />
                <Redirect from='/licenseProblems' to='/login' />
                <Route path='/api/console' component={ApplicationConsole} />
                <Route path='/logout' component={Logout} />
                <Route
                  path='/api/parentalConsent'
                  component={ParentalConsent}
                />
                <Route path='/version' component={VersionInformation} />
                <Route
                  path='/oauth/login'
                  render={(props) => (
                    <OauthLogin
                      {...props}
                      onShowErrorMessage={this.showDynamicErrorMessage}
                    />
                  )}
                />
                <Route
                  path='/preauth/:providerId'
                  render={(props) => (
                    <PreauthLogin
                      {...props}
                      onShowErrorMessage={this.showDynamicErrorMessage}
                    />
                  )}
                />
                <Route path='/noAccount' component={NonExistentAccountPage} />
                <Route path='*' render={() => <Redirect to='/login' />} />
              </Switch>
            </Main>
          </div>
        )}
      </>
    );
  }
}

function getDuration(timestamp: number): number {
  return Math.floor((Date.now() - timestamp) / 1000);
}

const mapStateToProps = (state) => ({
  loginState: state.user.loginState,
  cognitoProfile: state.user.cognitoProfile,
  userProfile: state.user.userProfile,
  entryState: state.entry.entryState,
});

const mapDispatchToProps = (dispatch) => ({
  onPlayTryout: () => dispatch(playTryout()),
  onPlay: () => dispatch(play()),
  onStop: () => dispatch(stop()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
